<template>
  <vca-column>
    <vca-row>
      <vca-card>
        <h3>{{ $t("events.list.date_filter.header") }}</h3>
        <vca-checkbox v-model="value.hide_past"
          >{{ $t("events.list.date_filter.hide_past") }}
        </vca-checkbox>
      </vca-card>
      <vca-card v-if="hasSystemPermission()">
        <h3>{{ $t("events.list.asp_type.header") }}</h3>
        <vca-checkbox v-model="value.asp_type" id="internal">
          {{ $t("events.list.asp_type.internal") }}
        </vca-checkbox>
        <vca-checkbox v-model="value.asp_type" id="event">
          {{ $t("events.list.asp_type.event") }}
        </vca-checkbox>
      </vca-card>
    </vca-row>
    <vca-row>
      <vca-card>
        <h3>{{ $t("events.list.type_filter.header") }}</h3>
        <vca-checkbox
          v-for="eventtype in eventtypes"
          v-model="value.type"
          :key="eventtype.value"
          :id="eventtype.value"
          >{{
            $t("events.eventtypes." + eventtype.value + ".title")
          }}</vca-checkbox
        >
      </vca-card>
      <vca-card>
        <h3>{{ $t("events.list.crew.header") }}</h3>
        <vca-dropdown
          v-model="value.crews"
          :multiple="true"
          :options="crewList"
          label=""
          :placeholder="$t('users.list.crew.select.placeholder')"
        ></vca-dropdown>
        <vca-field-row v-if="user">
          <button
            v-if="user.crew.id != ''"
            class="vca-button-small"
            @click="addOwnCrew()"
          >
            {{
              $t("context_button.add", {
                0: $t("events.list.crew.own"),
              })
            }}
          </button>
          <button
            v-if="user.crew.id != ''"
            class="vca-button-small"
            @click="setOwnCrew()"
          >
            {{
              $t("context_button.select", {
                0: $t("events.list.crew.own"),
              })
            }}
          </button>
        </vca-field-row>
      </vca-card>
    </vca-row>
  </vca-column>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "AccountEventAspFilter",
  props: {
    value: {
      type: Object,
      default: null,
    },
    events: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    setOwnCrew() {
      this.value.crews = [{ value: this.user.crew.crew_id }];
    },
    addOwnCrew() {
      if (!this.value.crews.find((el) => el.value == this.user.crew.crew_id)) {
        this.value.crews.push({ value: this.user.crew.crew_id });
      }
    },
  },
  computed: {
    eventsList() {
      return this.events.filter((event) => {
        if (event.crew_id == "") {
          return false;
        }
        event.crew.title = event.crew.name;
        event.crew.label = event.crew.name;
        event.crew.value = event.crew.id;
        return true;
      });
    },
    crewList() {
      let crew_list = [
        ...new Map(
          this.eventsList.map((item) => [item.crew["id"], item.crew])
        ).values(),
      ];
      // if there's any event without a crew_id, this is an office event - so we add the office to the crew dropdown
      if (
        this.events.find((item) => {
          return item.crew_id === "";
        }) !== undefined
      ) {
        crew_list.push({
          value: "",
          title: this.$t("events.list.crew.office_hh"),
          label: this.$t("events.list.crew.office_hh"),
        });
      }
      return crew_list;
    },
    eventtypes() {
      return this.event_types.filter((el) => {
        return el.value != "tour";
      });
    },
    ...mapGetters({
      hasSystemPermission: "user/roles/hasSystemPermission",
      event_types: "events/eventtypes",
      user: "user/current",
    }),
  },
};
</script>
